var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "ml-0 ml-md-6 py-6 px-4 px-md-16 rounded-lg full-width",
      attrs: { color: "light_gray", tile: "", elevation: "0" },
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12", lg: "10" } },
            [
              _c(
                "v-form",
                { ref: "profileForm" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "gray--text font-weight-bold text-body-1 text-capitalize",
                          },
                          [_vm._v(_vm._s(_vm.$t("label.deliveryAddresses")))]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-0",
                              attrs: {
                                text: "",
                                "min-width": "0",
                                ripple: false,
                                width: "auto",
                                height: "auto",
                                "min-height": "0",
                                plain: "",
                              },
                              on: { click: _vm.openAddAddressDialog },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "unbox_primary--text text-capitalize font-weight-bold text-body-1 cursor-pointer",
                                },
                                [_vm._v(_vm._s(_vm.$t("label.addAddress")))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [_c("v-divider")],
                        1
                      ),
                      _vm._l(_vm.customerInfo.addresses, function (address) {
                        return [
                          _c(
                            "v-col",
                            {
                              key: address.uuid + "_info",
                              staticClass: "my-0 py-0",
                              attrs: { cols: "12" },
                            },
                            [
                              address.is_default_billing
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "mr-2 white--text mb-2",
                                      attrs: {
                                        small: "",
                                        color: "unbox_primary",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.billingAddress"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              address.is_default_shipping
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "mr-2 white--text mb-2",
                                      attrs: {
                                        small: "",
                                        color: "unbox_primary",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.shippingAddress"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              key: address.uuid,
                              attrs: { cols: "12", sm: "8" },
                            },
                            [
                              _c(
                                "table",
                                { staticClass: "profile-address-table" },
                                [
                                  _c("tr", [
                                    _c("td", { attrs: { colspan: "2" } }),
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "pr-4 gray--text text-body-2",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.recipientName"))
                                        ),
                                      ]
                                    ),
                                    _c("td", { staticClass: "text-body-2" }, [
                                      _vm._v(
                                        " " + _vm._s(address.display_name) + " "
                                      ),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "pr-4 gray--text text-body-2",
                                      },
                                      [_vm._v(_vm._s(_vm.$t("label.phone")))]
                                    ),
                                    _c("td", { staticClass: "text-body-2" }, [
                                      _vm._v(
                                        "(" +
                                          _vm._s(address.phone_code) +
                                          ")" +
                                          _vm._s(address.phone_no)
                                      ),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "pr-4 gray--text text-body-2",
                                        attrs: { rowspan: "2" },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("label.address")))]
                                    ),
                                    _c("td", { staticClass: "text-body-2" }, [
                                      _vm._v(
                                        _vm._s(address.address_1) +
                                          " " +
                                          _vm._s(address.address_2) +
                                          " " +
                                          _vm._s(address.address_3)
                                      ),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "text-body-2" }, [
                                      _vm._v(
                                        _vm._s(address.city) +
                                          " " +
                                          _vm._s(address.postcode) +
                                          " " +
                                          _vm._s(address.state)
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              key: address.uuid + "_action",
                              staticClass: "text-right",
                              attrs: { cols: "12", sm: "4" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4 pa-0",
                                  attrs: {
                                    text: "",
                                    "min-width": "0",
                                    ripple: false,
                                    width: "auto",
                                    height: "auto",
                                    "min-height": "0",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAddress(address)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "unbox_primary--text text-capitalize font-weight-bold text-body-2 cursor-pointer",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("label.update")))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              key: address.uuid + "_divider",
                              attrs: { cols: "12" },
                            },
                            [_c("v-divider")],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }